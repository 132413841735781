<template>
  <div class="container">
    <Header :active="7" />
    <div class="banner-box">
      <el-image
        class="banner-box-img"
        src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/aboutUs/banner.png"
        lazy
      ></el-image>
      <div class="banner-box-text-box">
        <div class="banner-box-text-box-title">
          <div>不可辜负的信赖</div>
        </div>
        <div class="banner-box-text-box-tips">Relatives always accompany</div>
        <div class="horizontal-line m-t-21" style="background: white"></div>
      </div>
    </div>
    <div class="section1">
      <el-image
        class="section1-img"
        src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/aboutUs/section1-img4.png"
        lazy
      ></el-image>
      <el-image
        class="section1-bottom-img"
        src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/aboutUs/section1-img5.png"
        lazy
      ></el-image>
      <div class="section1-box">
        <div class="section1-box-left">
          <el-image
            class="section1-box-left-img1"
            src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/aboutUs/section1-img1.png"
            lazy
          ></el-image>
          <el-image
            class="section1-box-left-img2"
            src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/aboutUs/section1-img2.png"
            lazy
          ></el-image>
          <el-image
            class="section1-box-left-img3"
            src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/aboutUs/section1-img3.png"
            lazy
          ></el-image>
        </div>
        <div class="section1-box-right">
          <div class="section1-box-right-title">关于我们</div>
          <div class="section1-box-right-subtitle">About us</div>
          <div class="section1-box-right-info">
            我们 1CARE
            以及身边的每一个人，都有一个共同的目标，就是努力改善我们关心和爱护的人的健康状况；我们每个人都有一个高尚的故事和传承，这些故事或传承使我们团结起来朝着这个统一的使命前进；当我们朝着同一个目标努力时，我们会建立一种轻松协作的文化，并开启一段有益的个人和职业成长之旅。
          </div>
        </div>
      </div>
    </div>
    <div class="section2">
      <div class="section2-box">
        <div class="title-box">
          <div class="title">我们的团队</div>
          <div class="subtitle">Our team</div>
        </div>
      </div>
      <div class="section2-card m-t-75">
        <el-image
          class="section2-card-img1"
          src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/aboutUs/section2-img2.png"
          lazy
        ></el-image>
        <div class="section2-card-left m-r-48">
          <div class="section2-card-left-title">研发团队</div>
          <div>
            <el-image
              class="section2-card-left-img"
              src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/aboutUs/section2-img1.png"
              lazy
            ></el-image>
          </div>
        </div>
        <div class="section2-card-right">
          <!-- <div class="section2-card-right-title">
            <span class="section2-card-right-title-job"> AI技术机构师 </span>
            <span class="section2-card-right-title-name">刘超</span>
          </div> -->
          <div class="section2-card-right-info">
            由有世界500强、中科院工作经历的技术人才组成的研发团队，打造AI中台、物联网中台、数据中台、医疗知识中台四维一体的顶层架构，将业务、技术、数据有机结合起来；
          </div>
        </div>
      </div>
      <div class="section2-card" style="margin: 0.69791666rem auto">
        <el-image
          class="section2-card-img2"
          src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/aboutUs/section2-img4.png"
          lazy
        ></el-image>
        <div class="section2-card-left">
          <div class="section2-card-left-title">管理团队</div>
          <div>
            <el-image
              class="section2-card-left-img"
              src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/aboutUs/section2-img3.png"
              lazy
            ></el-image>
          </div>
        </div>
        <div class="section2-card-right">
          <!-- <div class="section2-card-right-title">
            <span class="section2-card-right-title-job"> AI技术机构师 </span>
            <span class="section2-card-right-title-name">刘超</span>
          </div> -->
          <div class="section2-card-right-info">
            由国内著名医疗上市公司管理经验的团队，负责公司运营管理；
            在面对企业复杂项目或者新的任务场景时游刃有余；
          </div>
        </div>
      </div>
      <div class="section2-card">
        <el-image
          class="section2-card-img3"
          src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/aboutUs/section2-img6.png"
          lazy
        ></el-image>
        <div class="section2-card-left">
          <div class="section2-card-left-title">医学团队</div>
          <div>
            <el-image
              class="section2-card-left-img"
              src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/aboutUs/section2-img5.png"
              lazy
            ></el-image>
          </div>
        </div>
        <div class="section2-card-right">
          <!-- <div class="section2-card-right-title">
            <span class="section2-card-right-title-job"> AI技术机构师 </span>
            <span class="section2-card-right-title-name">刘超</span>
          </div> -->
          <div class="section2-card-right-info">
            联合国内多家著名三级综合性医院，联手打造集医疗AI中台、医疗知识中台、医疗大数据中台、健康监测业务中台、多端场景一体化的智慧健康平台；提供临床决策支持、健康监测、慢病管理、慢病早期预警等产品系列，实现全生命周期智慧化管理，提升区域慢病防治水平，助益健康中国战略；
          </div>
        </div>
      </div>
    </div>
    <div class="section3">
      <div class="section3-box">
        <div class="section3-box-left">
          <div class="section3-box-left-title">
            <span>我们的理念</span>
            <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/icon/arrow-down.png" alt="" />
          </div>
          <div class="section3-box-left-subtitle">Our philosophy</div>
          <div class="section3-box-left-box">
            <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/icon/checked.png" alt="" />
            <div class="section3-box-left-box-info">
              我们1CARE的宗旨是重塑健康生活新模式，有效提高生存质量，防止或延缓慢性病的发生与发展；
            </div>
          </div>
          <div class="section3-box-left-box">
            <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/icon/checked.png" alt="" />
            <div class="section3-box-left-box-info">
              我们的人工智能平台，通过多模感知技术，将长久以来人们形成的有病才治疗的被动健康模式，转变为以治未病、预防为主的主动健康模式，提高全民健康水平；
            </div>
          </div>
          <div class="section3-box-left-box">
            <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/icon/checked.png" alt="" />
            <div class="section3-box-left-box-info">
              我们1CARE的人工智能、机器学习和先进算法，可以为我们提供专业、早期的健康预警，让我们可以有针对性的提供最佳护理服务，老人不用离开熟悉的环境、家人和朋友，可以安心、独立、幸福的实现居家养老，而不用脱离社会；
            </div>
          </div>
          <div class="section3-box-left-img-box">
            <el-image
              class="banner-box-img"
              src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/aboutUs/section3-img1.png"
              lazy
            ></el-image>
            <el-image
              class="banner-box-img"
              src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/aboutUs/section3-img2.png"
              lazy
            ></el-image>
          </div>
        </div>
        <div class="section3-box-right" id="target">
          <div class="section3-box-right-title">联系我们</div>
          <div class="section3-box-right-subtitle">Contact us</div>
          <div class="section3-box-right-form">
            <div class="section3-box-right-form-input">
              <div class="section3-box-right-form-input-label">姓名</div>
              :
              <input type="text" v-model="name" />
            </div>
            <div class="section3-box-right-form-input">
              <div class="section3-box-right-form-input-label">公司</div>
              :
              <input type="text" v-model="institution" />
            </div>
            <!-- <div class="section3-box-right-form-input">
              <div class="section3-box-right-form-input-label">兴趣所在</div>
              :
              <input type="text" v-model="interest" />
            </div> -->
            <div class="section3-box-right-form-input">
              <div class="section3-box-right-form-input-label">电话</div>
              :
              <input type="tel" v-model="phone" />
            </div>
            <div class="section3-box-right-form-input">
              <div class="section3-box-right-form-input-label">邮箱</div>
              :
              <input type="email" v-model="email" />
            </div>
            <div class="section3-box-right-form-area">
              <textarea
                type="area"
                v-model="message"
                placeholder="请留下您需要帮助的内容 ~"
              />
            </div>
            <div class="section3-box-right-form-btn" @click="submit">提 交</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { validEmail, validMobile } from "../utils/my-validate.js";
export default {
  name: "aboutUs",
  data() {
    return {
      name: "",
      institution: "",
      interest: "",
      phone: "",
      email: "",
      message: "",
    };
  },
  mounted() {
    console.log(this.$route.query);
    if (this.$route.query.target) {
      this.$nextTick(() => {
        const dom = document.querySelector(`#target`);
        console.log("dom", dom);
        // 调用dom的scrollIntoView方法
        if (dom) {
          dom.scrollIntoView(true);
        }
      });
    }
  },
  methods: {
    submit() {
      const { name, institution, phone, email, message } = this;
      if (!name || !institution || !phone || !email || !message) {
        this.$alert("请将表单填写完整！", "提示", {
          confirmButtonText: "确定",
        });
      } else {
        if (!validMobile(phone)) {
          this.$message({
            type: "warning",
            message: "请输入合法的手机号",
          });
          return;
        }
        if (!validEmail(email)) {
          this.$message({
            type: "warning",
            message: "请输入合法的邮箱",
          });
          return;
        }
        axios
          .post("https://api.link-lab.com.cn/aiot-messagecenter/notice/email", {
            recipientMailbox: ["service@lian-lai.com"],
            emailSubject: `${institution}-${name} 申请1CARE健康使用， 联系电话是${phone}，邮箱地址： ${email}`,
            flag: true,
            messageBody: message,
          })
          .then((res) => {
            console.log("submit", res);
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "提交成功！",
              });
            }
          });
        // this.$alert("提交成功！", "提示", {
        //   confirmButtonText: "确定",
        //   callback: () => {
        //     this.name = "";
        //     this.institution = "";
        //     this.interest = "";
        //     this.phone = "";
        //     this.email = "";
        //     this.message = "";
        //   },
        // });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  background: #eef1f5;
}
.horizontal-line {
  width: 73px;
  height: 5px;
  background: #90c446;
}
.title-box {
  .title {
    font-family: "UI-Bold";
    font-size: 52px;
    color: #1f3876;
  }
  .subtitle {
    font-family: "CN-Light";
    font-size: 31px;
    margin-top: 5px;
    color: #90c446;
  }
  .info {
    display: flex;
    margin-top: 29px;
    &-left {
      margin-right: 30px;
      img {
        width: 23px;
        height: 24px;
      }
    }
    &-right {
      flex: 1;
      font-family: "CN-Light";
      font-size: 21px;
      color: #727171;
    }
  }
}
.banner-box {
  position: relative;
  background: white;
  &-img {
    width: 100%;
    // position: absolute;
    // left: 0;
    // right: 0;
    // z-index: -1;
  }
  &-text-box {
    position: absolute;
    width: 1200px;
    box-sizing: border-box;
    // height: 300px;
    // left: 360px;
    top: 127px;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: white;
    &-title {
      font-size: 64px;
      font-family: "UI-Bold";
    }
    &-tips {
      font-family: "CN-Light";
      font-size: 31px;
      margin-top: 14px;
    }
  }
}
.section1 {
  background: white;
  width: 100%;
  height: 736px;
  position: relative;
  &-img {
    position: absolute;
    width: 50%;
    top: 400px;
    right: 0;
  }
  &-bottom-img {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
  }
  &-box {
    width: 1200px;
    height: 580px;
    margin: 0 auto;
    padding-top: 20px;
    display: flex;
    &-left {
      width: 50%;
      height: 100%;
      position: relative;
      cursor: pointer;
      &-img1 {
        width: 254px;
        height: 329px;
        position: absolute;
        top: 0;
        left: 20px;
        transition: all 0.8s;
      }
      &-img2 {
        width: 224px;
        height: 220px;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: all 0.8s;
      }
      &-img3 {
        width: 499px;
        height: 439px;
        position: absolute;
        top: 100px;
        right: 0px;
        transition: all 0.8s;
      }
      &-img1:hover {
        transform: scale(1.02);
      }
      &-img2:hover {
        transform: scale(1.02);
      }
      &-img3:hover {
        transform: scale(1.02);
      }
    }
    &-right {
      width: 50%;
      margin-left: 30px;
      &-title {
        margin-top: 120px;
        font-size: 52px;
        font-family: "UI-Bold";
        color: #1f3876;
      }
      &-subtitle {
        font-family: "CN-Light";
        font-size: 31px;
        color: #90c446;
      }
      &-info {
        font-family: "CN-Light";
        font-size: 21px;
        color: #727171;
        margin-top: 40px;
      }
    }
  }
}
.section2 {
  padding-top: 148px;
  background: #eef1f5;
  padding-bottom: 128px;
  &-box {
    width: 1200px;
    margin: 0 auto;
  }

  &-card {
    width: 1100px;
    height: 393px;
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 10.4px 9.37px 35px 14px rgba(0, 15, 33, 0.1);
    margin: 0 auto;
    box-sizing: border-box;
    padding: 40px 60px;
    position: relative;
    display: flex;
    cursor: pointer;
    transition: all 0.8s;
    &-img1 {
      position: absolute;
      width: 373px;
      height: 372px;
      bottom: 50px;
      right: -92px;
    }
    &-img2 {
      position: absolute;
      width: 278px;
      height: 443px;
      bottom: 28px;
      right: -100px;
    }
    &-img3 {
      position: absolute;
      width: 301px;
      height: 410px;
      bottom: 54px;
      right: -101px;
    }
    &-left {
      margin-right: 127px;
      &-title {
        font-family: "CN-Medium";
        font-size: 45px;
        color: #1f3876;
      }
      &-img {
        width: 355px;
        height: 230px;
        margin-top: 26px;
      }
    }
    &-right {
      width: 328px;
      &-title {
        text-align: right;
        margin-top: 18px;
        &-job {
          font-family: "CN-Regular";
          font-size: 23px;
          color: #1f3876;
        }
        &-name {
          font-family: "CN-Bold";
          font-size: 30px;
          color: #90c446;
          margin-left: 20px;
        }
      }
      &-info {
        font-family: "CN-Light";
        font-size: 21px;
        color: #727171;
        margin-top: 47px;
        text-align: justify;
      }
    }
  }
  &-card:hover {
    transform: scale(1.02);
  }
}
.section3 {
  position: relative;
  width: 100%;
  background: #f7f7f9 url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/aboutUs/section3-bg.png") no-repeat;
  background-size: 100% auto;
  background-position: left top;
  &-box {
    width: 1230px;
    margin: 0 auto;
    padding-top: 340px;
    padding-bottom: 166px;
    display: flex;
    justify-content: space-between;
    &-left {
      margin-right: 10px;
      padding-top: 20px;
      &-title {
        font-family: "UI-Bold";
        font-size: 52px;
        color: #1f3876;
        display: flex;
        align-items: center;
        img {
          width: 30px;
          height: 30px;
          margin-left: 50px;
        }
      }
      &-subtitle {
        font-family: "CN-Light";
        font-size: 31px;
        color: #90c446;
        margin-bottom: 62px;
      }
      &-box {
        display: flex;
        margin-bottom: 22px;
        justify-content: space-between;
        img {
          width: 29px;
          height: 20px;
          // margin-right: 30px;
          margin-top: 8px;
        }
        &-info {
          width: 482px;
          font-family: "CN-Light";
          font-size: 21px;
          color: #727171;
        }
      }
      &-img-box {
        display: flex;
        padding-left: 30px;
        margin-top: 63px;
        .el-image {
          width: 182px;
          height: 181px;
          margin-right: 89px;
        }
      }
    }
    &-right {
      width: 569px;
      height: 881px;
      position: relative;
      box-sizing: border-box;
      padding: 60px 90px 60px 80px;
      background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/aboutUs/section3-img3.png") no-repeat;
      background-size: 100% 100%;
      &-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
      }
      &-title {
        font-family: "UI-Bold";
        font-size: 52px;
        color: white;
      }
      &-subtitle {
        font-family: "CN-Light";
        font-size: 25px;
        color: white;
      }
      &-form {
        width: 100%;
        margin-top: 40px;
        font-size: 16px;
        font-family: "CN-Normal";
        color: #b7b7b7;
        &-input {
          width: 100%;
          box-sizing: border-box;
          height: 41px;
          background: #ffffff;
          border-radius: 20px;
          box-shadow: 5.2px 4.68px 8px 7px rgba(0, 15, 33, 0.1);
          display: flex;
          align-items: center;
          padding: 0 22px;
          margin-bottom: 25px;
          &-label {
            width: 72px;
            text-align: justify;
            text-align-last: justify;
            font-size: 16px;
            font-family: "CN-Normal";
            color: #b7b7b7;
          }
          input {
            outline: none;
            border: none;
            flex: 1;
            color: #b7b7b7;
            font-size: 16px;
            font-family: "CN-Normal";
            padding-left: 20px;
          }
        }
        &-area {
          margin-top: 42px;
          width: 100%;
          box-sizing: border-box;
          height: 112px;
          background: #ffffff;
          border-radius: 17px;
          box-shadow: 5.2px 4.68px 8px 7px rgba(0, 15, 33, 0.2);
          padding: 21px;
          textarea {
            width: 100%;
            height: 100%;
            outline: none;
            border: none;
            color: #b7b7b7;
            font-size: 16px;
            font-family: "CN-Normal";
            resize: none;
          }
          textarea::-webkit-input-placeholder {
            color: #b7b7b7;
            font-size: 16px;
            font-family: "CN-Normal";
          } /*webkit 内核浏览器*/
          textarea::-moz-placeholder {
            color: #b7b7b7;
            font-size: 16px;
            font-family: "CN-Normal";
          } /*Mozilla Firefox 19+*/
          textarea:-moz-placeholder {
            color: #b7b7b7;
            font-size: 16px;
            font-family: "CN-Normal";
          } /*Mozilla Firefox 4 to 18*/
          textarea:-ms-input-placeholder {
            color: #b7b7b7;
            font-size: 16px;
            font-family: "CN-Normal";
          }
        }
        &-btn {
          width: 128px;
          height: 41px;
          background: #90c446;
          border-radius: 20px;
          box-shadow: 5.2px 4.68px 8px 7px rgba(0, 15, 33, 0.2);
          color: white;
          margin-top: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: "CN-Bold";
          font-size: 18px;
          transition: all 0.8s;
          cursor: pointer;
        }
        &-btn:hover {
          transform: translateY(-3px);
          box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}
</style>
